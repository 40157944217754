import React, { FC } from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

export const Image: FC = () => (
  <StaticQuery<GatsbyTypes.PlaceholderImageQuery>
    query={graphql`
      query PlaceholderImage {
        placeholderImage: file(
          relativePath: { eq: "react-native-dev-logo.png" }
        ) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Img fixed={data?.placeholderImage?.childImageSharp?.fixed} />
    )}
  />
);
