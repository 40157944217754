import React, { FC } from "react";
import { Image } from "./image";

interface HeaderProps {
  siteTitle?: string;
}

const Header: FC<HeaderProps> = ({ siteTitle = "" }) => (
  <header
    style={{
      background: `transparent`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Image />
      <h1 style={{ margin: "0 0 0 5px", color: "#333333" }}>{siteTitle}</h1>
    </div>
  </header>
);

export default Header;
